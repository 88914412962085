import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../components/Buttons/PurpleSolidButton"
import StuckFs from "../components/fullscreen/StuckFs"
import Seo from "../components/Seo"
import StuckPixelFixTr from "../contents/global/tr/StuckPixelFixTr"
import SvgFAQ from "../contents/SVG/SvgFAQ"
const SikismisPikselTamiri = () => {
  const stuckRef = useRef()
  return (
    <>
      <Seo
        title="Sıkışmış Piksel Tamiri"
        description="Android, iphone tablet ve telefonlarınızda, akıllı televizyonlarınızda, monitör ve bilgisayar ekranlarınızdaki sıkışmış pikselleri ücretsiz online tamir edin."
      />
      <h1 className="text-center md:text-left">
        Sıkışmış Piksel Tamiri Nasıl Yapılır?
      </h1>
      <p>
        <strong>Sıkışmış Piksel Onarım Aracını</strong> aşağıdaki{" "}
        <code>Sıkışmış Piksel Tamiri</code> düğmesine tıklayarak herhangi bir
        kurulum gerektirmeksizin tarayıcınızda çalıştırabilirsiniz.
      </p>
      <p>
        <strong>Sıkışmış Piksel Onarım Aracı</strong> telefon, tablet,
        bilgisayar, televizyon dahil modern internet tarayıcısı (
        <span className="italic text-red-400">
          - Internet Explorer eski sürümlerinde çalışmaz
        </span>
        ) olan her cihazınızda kullanabilirsiniz.
      </p>
      <blockquote>
        Sıkışmış piksel onarımını uygulamadan önce bilgisayar ve ekran
        ayarlarınızın uyku süresini kontrol ediniz.{" "}
        <strong>Sıkışmış Piksel Onarım Aracı</strong> açık bir ekrana ihtiyaç
        duyar.
      </blockquote>
      <div className="py-3 text-center">
        <PurpleSolidButton
          text="Sıkışmış Piksel Tamiri"
          onClick={() => stuckRef.current.open()}
        />
      </div>
      <h2>Sıkışmış Piksel Nedir, Sıkışmış Piksel Neden Oluşur?</h2>
      <p>
        <strong>Sıkışmış</strong> ya da diğer bir deyişle{" "}
        <strong>takılmış</strong> pikseller genelde bir renkte(mavi, yeşil,
        kırmızı,vs.) takılıp kalan ekrandaki noktalardır.
      </p>
      <SvgFAQ className="max-w-full px-3 md:px-10" />
      <p>
        Sıkışmış (takılmış) pikseller genellikle piksel üzerinde uzun bir süre
        renk değişiminin olamaması nedeniyle oluşurlar. Bu şekilde oluşan
        sıkışmış pikseller <code>Sıkışmış Piksel Tamir Aracı</code> kullanılarak
        eski hallerine getirilebilir.
      </p>
      <h2>Ölü Pikselle Sıkışmış Piksel Arasındaki Fark Nedir?</h2>
      <p>
        Bazen <code>ölü piksel</code> olarak adlandırdığımız pikseller{" "}
        <strong>sıkışmış (takılmış)</strong>&nbsp; piksel olabilir.{" "}
        <strong>Ölü piksel</strong> ile{" "}
        <span className="font-bold text-blue-600">sıkışmış piksel</span>{" "}
        arasındaki temel fark,{" "}
        <span className="font-bold text-red-600">sıkışmış piksel</span> enerji
        alabiliyorken, <strong>ölü pikselin</strong> ömrü tükenmiştir. Bu yüzden{" "}
        <strong>ölü pikseller</strong> daha çok <strong>siyah</strong> renkte
        iken{" "}
        <span className="font-bold text-green-400">sıkışmış pikseller</span>{" "}
        farklı renklerde olabilirler.
      </p>
      <blockquote>
        Her siyah nokta ölü piksel değildir, sıkışmış piksel de olabilir.
      </blockquote>
      <p>
        Yukarıdaki bilgilere ek olarak <strong>ölü piksel</strong> hatasının
        tamiri yazılımsal olarak mümkün gibi değilken sıkışmış piksellerin
        düzelme ihtimali vardır.
      </p>
      <h2>Ekranımda Sıkışmış (Takılmış) Piksel Olduğunu Nasıl Anlarım?</h2>
      <p>
        Aşağıdaki <code>Sıkışmış Piksel Testi</code> düğmesine tıklayarak açılan
        sayfadaki test aracını kullanabilisiniz. Takılmış pikseller için ekran
        testi yaparken ekranda gözlemlediğiniz noktalar <strong>siyah</strong>{" "}
        ve{" "}
        <span className="px-2 py-1 text-sm font-bold text-white bg-gray-800 rounded-sm">
          beyazdan
        </span>{" "}
        ziyade <span className="font-bold text-blue-600">mavi</span>,{" "}
        <span className="font-bold text-red-600">kırmızı</span>,{" "}
        <span className="font-bold text-green-400">yeşil</span> gibi renklerde
        ise ekranınızda muhtemelen <code>sıkışmış piksel</code> vardır.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/olu-piksel-testi"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Sıkışmış Piksel Testi</span>
        </Link>
      </div>
      <StuckFs
        ref={stuckRef}
        starter={
          <StuckPixelFixTr startRepair={() => stuckRef.current.start()} />
        }
      />
    </>
  )
}
export default SikismisPikselTamiri
