import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
const StuckPixelFixTr = ({ startRepair }) => {
  return (
    <div className="px-3 prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
      <p className="lead">
        <code>Tamiri Başlat</code> düğmesine tıklayınca ekranınız siyaha dönecek
        ve karşısınıza içinde renk geçişleri olan bir kare çıkacak. O kareyi
        sürükleyip sıkışmış piksellerinizin üzerine getirin ve 10 - 15 dk bu
        şekilde bekleyin.
      </p>
      <blockquote>
        Güç ayarlarınızdan ekranın kapanmamasına yönelik ayarlar yapmayı
        unutmayın.
      </blockquote>
      <p>
        Sıkışmış piksel tamir işlemini sonlandırıp ekranı kapatmak için{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        tuşuna basabilirsiniz.
      </p>
      <div className="text-center">
        <PurpleSolidButton text="Tamiri Başlat" onClick={startRepair} />
      </div>
    </div>
  )
}
export default memo(StuckPixelFixTr)
